import { useAtom } from "jotai";
import { quizResultAtom, quizItemIdListAtom } from "./State";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import quizItems from "./QuizItems";
import { CRuby } from "./CRuby";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const Result = () => {
  const threshold = 0.7; // 合格のしきい値
  const [failedQuizList, setFailedQuizList] = useAtom(quizResultAtom); // 間違ったクイズのリスト
  const [quizItemIdList, setQuizItemList] = useAtom(quizItemIdListAtom);
  const navigate = useNavigate();

  const [score, setScore] = useState(0);
  const [result, setResult] = useState("");
  const [isPerfect, setIsPerfect] = useState(false);
  const failedQuiz = quizItems.filter((item) =>
    failedQuizList.includes(item.id)
  );

  useEffect(() => {
    const failed = failedQuizList.length;
    const questions = quizItemIdList.length;
    const correct = questions - failed;
    const score = correct / questions;
    score >= threshold ? setResult("合格!") : setResult("不合格...");
    if (score == 1.0) {
      setIsPerfect(true);
    }
    setScore(score);
  }, []);

  const handleMove = (moveTo) => {
    navigate(moveTo);
    setFailedQuizList([]);
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-4">
        <h1 className="text-3xl text-center my-8 underline">テストの結果</h1>
        <div className="text-5xl text-center my-2">
          {Math.round(score * 100)}点
        </div>
        <div className="text-7xl text-center my-4">{result}</div>
        {!isPerfect && (
          <div id="resultArea" className="max-h-80 overflow-y-auto">
            <div className="text-2xl text-center mt-12 mb-4 ">間違った問題</div>
            <Accordion>
              {failedQuiz.map((quizItem) => {
                return (
                  <AccordionItem key={quizItem.id}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <div className="flex flex-rows items-end">
                          <div className="mr-2">{quizItem.id}.</div>
                          <CRuby text={quizItem.question} />
                        </div>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        <div>
                          <h2 className="font-bold">[こたえ]</h2>
                          <CRuby text={quizItem.correct} />
                        </div>
                        <div>
                          <h2 className="font-bold">[説明]</h2>
                          <CRuby text="ちょっとまってね" />
                          {/* <CRuby text={quizItem.explanation} /> */}
                        </div>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        )}
        <div className="flex flex-col justify-center my-4 ">
          {/* <button
            onClick={() => handleMove("/quiz")}
            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-4 rounded my-2 text-xl"
          >
            クイズに戻る
          </button> */}
          <button
            onClick={() => handleMove("/")}
            className="bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-4 rounded my-2 text-xl"
          >
            メニューに戻る
          </button>
        </div>
      </div>
    </div>
  );
};
export default Result;
