import "./App.css";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import quizItems from "./QuizItems";

const Home = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h1 className="text-4xl mb-8">テスト練習しよう</h1>
      <img src={logo} className="w-64 rounded-lg"></img>
      <ul className="m-2">
        <li>
          <div>
            <Link to="/quiz?size=10">
              <div className="text-2xl hover:text-3xl hover:font-bold hover:bg-lime-200 p-2 mx-2 my-4 text-center">
                恐竜検定(10問)
              </div>
            </Link>
            <Link to="/quiz?size=20">
              <div className="text-2xl hover:text-3xl hover:font-bold hover:bg-lime-200 p-2 mx-2 my-4 text-center">
                恐竜検定(20問)
              </div>
            </Link>
            <Link to="/quiz?size=50">
              <div className="text-2xl hover:text-3xl hover:font-bold hover:bg-lime-200 p-2 mx-2 my-4 text-center">
                恐竜検定(50問)
              </div>
            </Link>
            <Link to="/quiz?size=100">
              <div className="text-2xl hover:text-3xl hover:font-bold hover:bg-lime-200 p-2 mx-2 my-4 text-center">
                恐竜検定(100問)
              </div>
            </Link>
            <Link to="/quiz">
              <div className="text-2xl hover:text-3xl hover:font-bold hover:bg-lime-200 p-2 mx-2 my-4 text-center">
                恐竜検定(全{quizItems.length}問)
              </div>
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default Home;
