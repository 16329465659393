const quizItems = [
  {
    id: 1,
    question: "恐竜が(繁栄)[はんえい]していた時代はどれ?",
    answerItems: ["先カンブリア紀", "古生代", "中生代", "新生代"],
    correct: "中生代",
    explanation: "",
  },
  {
    id: 2,
    question: "中生代は、(三畳紀)[さんじょうき]、ジュラ紀と何に分けられる?",
    answerItems: ["石炭紀", "デボン紀", "ペルム紀", "白亜紀"],
    correct: "白亜紀",
    explanation: "",
  },
  {
    id: 3,
    question: "恐竜が(現)[あらわ]れたのはどの時代?",
    answerItems: ["ペルム紀", "(三畳紀)[さんじょうき]", "ジュラ紀", "白亜紀"],
    correct: "(三畳紀)[さんじょうき]",
    explanation: "",
  },
  {
    id: 4,
    question: "恐竜が大量(絶滅)[ぜつめつ]したのはどのくらい前?",
    answerItems: ["66万年前", "660万年前", "6600万年前", "6億6000万年前"],
    correct: "6600万年前",
    explanation: "",
  },
  {
    id: 5,
    question: "恐竜がいた時代の(気候)[きこう]を今とくらべると?",
    answerItems: [
      "今より(暖)[あたた]かかった",
      "今より(寒)[さむ]かった",
      "今と変わらなかった",
      "雪ばかり(降)[ふ]っていたい",
    ],
    correct: "今より(暖)[あたた]かかった",
    explanation: "",
  },
  {
    id: 6,
    question: "恐竜はどの生き物のなかま?",
    answerItems: [
      "ライオンなどと同じ(哺乳類)[ほにゅうるい]",
      "カエルなどと同じ(両生類)[りょうせいるい]",
      "ワニなどと同じ(爬虫類)[はちゅうるい]",
      "メダカなどと同じ(魚類)[ぎょるい]",
    ],
    correct: "ワニなどと同じ(爬虫類)[はちゅうるい]",
    explanation: "",
  },
  {
    id: 7,
    question: "恐竜に一番近い、今生きている(爬虫類)[はちゅうるい]は?",
    answerItems: ["ヘビ", "トカゲ", "カメ", "ワニ"],
    correct: "ワニ",
    explanation: "",
  },
  {
    id: 8,
    question:
      "今まで発見された恐竜の(種類)[しゅるい]はどれくらい?(ただし、鳥をのぞく)",
    answerItems: ["約100種類", "約500種類", "約1000種類", "約2000種類"],
    correct: "約1000種類",
    explanation: "",
  },
  {
    id: 9,
    question: "大きな群れをつくる恐竜は、何を食べていた?",
    answerItems: ["肉", "魚", "植物", "(雑食)[ざっしょく]"],
    correct: "植物",
    explanation: "",
  },
  {
    id: 10,
    question: "恐竜は、(竜盤類)[りゅうばんるい]と何類に分けられる?",
    answerItems: [
      "(竜脚類)[りゅうきゃくるい]",
      "(角竜類)[つのりゅうるい]",
      "(剣竜類)[けんりゅうるい]",
      "(鳥盤類)[ちょうばんるい]",
    ],
    correct: "(鳥盤類)[ちょうばんるい]",
    explanation: "",
  },
  {
    id: 11,
    question:
      "(竜盤類)[りゅうばんるい]の(骨盤)[こつばん]の(一般的)[いっぱんてき]な特ちょうは?",
    answerItems: [
      "(恥骨)[ちこつ]が前にのびる",
      "(恥骨)[ちこつ]が(坐骨)[ざこつ]にそってのびる",
      "(恥骨)[ちこつ]がない",
      "(坐骨)[ざこつ]がない",
    ],
    correct: "(恥骨)[ちこつ]が前にのびる",
    explanation: "",
  },
  {
    id: 12,
    question:
      "(鳥盤類)[ちょうばんるい]の(骨盤)[こつばん]の(一般的)[いっぱんてき]な特ちょうは?",
    answerItems: [
      "(恥骨)[ちこつ]が前にのびる",
      "(恥骨)[ちこつ]が(坐骨)[ざこつ]にそってのびる",
      "(恥骨)[ちこつ]がない",
      "(坐骨)[ざこつ]がない",
    ],
    correct: "(恥骨)[ちこつ]が(坐骨)[ざこつ]にそってのびる",
    explanation: "",
  },
  {
    id: 13,
    question: "恐竜のあしについて正しいのは?",
    answerItems: [
      "体の真下にのびる",
      "体の真上にのびる",
      "全て同じ高さ",
      "ひれになっている",
    ],
    correct: "体の真下にのびる",
    explanation: "",
  },
  {
    id: 14,
    question: "骨の化石について正しいのは?",
    answerItems: [
      "全ての恐竜の骨が化石になった",
      "必ず全身の骨がそろって見つかる",
      "骨は化石にならない",
      "全身の骨が見つかることはほとんどない",
    ],
    correct: "全身の骨が見つかることはほとんどない",
    explanation: "",
  },
  {
    id: 15,
    question: "ふん(うんち)の化石からわかることは?",
    answerItems: ["身長", "食べていたもの", "体の色", "鳴き声"],
    correct: "食べていたもの",
    explanation: "",
  },
  {
    id: 16,
    question: "足あとの化石からわかることは?",
    answerItems: ["食べていたもの", "歯の様子", "目のよさ", "移動の速度"],
    correct: "移動の速度",
    explanation: "",
  },
  {
    id: 17,
    question: "ティラノサウルスはどのなかま?",
    answerItems: [
      "(鳥脚類)[ちょうきゃくるい]",
      "(竜脚類)[りゅうきゃくるい]",
      "(獣脚類)[じゅうきゃくるい]",
      "(装盾類)[そうじゅんるい]",
    ],
    correct: "(獣脚類)[じゅうきゃくるい]",
    explanation: "",
  },
  {
    id: 18,
    question: "(獣脚類)[じゅうきゃくるい]は、何本のあしで歩いた?",
    answerItems: ["1", "2", "3", "4"],
    correct: "2",
    explanation: "",
  },
  {
    id: 19,
    question: "コエロフィシスが食べていたことが、化石からわかっているものは?",
    answerItems: ["きのこ", "草", "トカゲ", "魚"],
    correct: "トカゲ",
    explanation: "",
  },
  {
    id: 20,
    question: "ディロフォサウルスの頭の特ちょうは?",
    answerItems: [
      "鼻の先の角",
      "大きなフリル",
      "頭の後ろにのびる(突起)[とっき]",
      "2列のとさか状の(突起)[とっき]",
    ],
    correct: "2列のとさか状の(突起)[とっき]",
    explanation: "",
  },
  {
    id: 21,
    question: "ケラトサウルスの名前の意味は?",
    answerItems: [
      "つめのあるトカゲ",
      "角のあるトカゲ",
      "羽毛のあるトカゲ",
      "(飾)[かざ]りのあるトカゲ",
    ],
    correct: "角のあるトカゲ",
    explanation: "",
  },
  {
    id: 22,
    question: "ケラトサウルスと同じ時代、同じ(地域)[ちいき]にくらしていたのは?",
    answerItems: [
      "アロサウルス",
      "ティラノサウルス",
      "トリケラトプス",
      "パラサウロロフス",
    ],
    correct: "アロサウルス",
    explanation: "",
  },
  {
    id: 23,
    question: "アロサウルスの名前の意味は?",
    answerItems: [
      "本当のトカゲ",
      "おもしろいトカゲ",
      "そっくりなトカゲ",
      "(異)[こと]なるトカゲ",
    ],
    correct: "(異)[こと]なるトカゲ",
    explanation: "",
  },
  {
    id: 24,
    question: "アロサウルスの特ちょうは?",
    answerItems: [
      "あごの力が恐竜でいちばんだった",
      "(狩)[か]りはしなかった",
      "頭の骨ががんじょうだった",
      "(翼)[つばさ]が生えていた",
    ],
    correct: "頭の骨ががんじょうだった",
    explanation: "",
  },
  {
    id: 25,
    question: "スピノサウルスの特ちょうは?",
    answerItems: [
      "長い角",
      "(背中)[せなか]の大きな(帆)[ほ]",
      "尾の先のとげ",
      "後ろあしの大きなかぎづめ",
    ],
    correct: "(背中)[せなか]の大きな(帆)[ほ]",
    explanation: "",
  },
  {
    id: 26,
    question: "スピノサウルスは主にどこでくらしていた?",
    answerItems: ["(砂漠)[さばく]", "(水辺)[みずべ]", "木の上", "地面の中"],
    correct: "(水辺)[みずべ]",
    explanation: "",
  },
  {
    id: 27,
    question: "スピノサウルスのなかまは、なぜ上手に魚をつかまえられた?",
    answerItems: [
      "足がとても速いから",
      "(舌)[した]が長いから",
      "歯がまっすぐだから",
      "前足が長いから",
    ],
    correct: "歯がまっすぐだから",
    explanation: "",
  },
  {
    id: 28,
    question: "バリオニクスが食べたいたものは?",
    answerItems: ["魚", "昆虫", "水中の草", "ネズミ"],
    correct: "魚",
    explanation: "",
  },
  {
    id: 29,
    question: "バリオニクスの名前の意味は?",
    answerItems: ["細長い顔", "長い尾", "水中のハンター", "重いつめ"],
    correct: "重いつめ",
    explanation: "",
  },
  {
    id: 30,
    question: "コンプソグナトゥスの大きさはどれくらい?",
    answerItems: [
      "ネズミくらい",
      "ニワトリくらい",
      "ライオンくらい",
      "ゾウくらい",
    ],
    correct: "ニワトリくらい",
    explanation: "",
  },
  {
    id: 31,
    question: "ティラノサウルスのおとなの全長は?",
    answerItems: ["4〜5m", "8〜9m", "12〜13m", "17〜18m"],
    correct: "12〜13m",
    explanation: "",
  },
  {
    id: 32,
    question: "ティラノサウルスの名前の意味は?",
    answerItems: [
      "大きなあごのトカゲ",
      "最強のトカゲ",
      "あばれんぼうトカゲ",
      "頭の大きなトカゲ",
    ],
    correct: "あばれんぼうトカゲ",
    explanation: "",
  },
  {
    id: 33,
    question: "ティラノサウルスは短い前あしをどう使ったと考えられている?",
    answerItems: [
      "歯の(掃除)[そうじ]をした",
      "起き上がるときに体を支えた",
      "地面を掘った",
      "木の葉を集めた",
    ],
    correct: "起き上がるときに体を支えた",
    explanation: "",
  },
  {
    id: 34,
    question: "ティラノサウルスの歯のひみつは?",
    answerItems: [
      "ダイヤモンドよりもかたかった",
      "毎日全ての歯が生えかわった",
      "のこぎりのようなギザギザがあった",
      "歯が丸かった",
    ],
    correct: "のこぎりのようなギザギザがあった",
    explanation: "",
  },
  {
    id: 35,
    question: "ティラノサウルスのなかまの、前歯の数本の(断面)[だんめん]の形は?",
    answerItems: ["うすいレンズ形", "D字形", "丸形", "三角形"],
    correct: "D字形",
    explanation: "",
  },
  {
    id: 36,
    question: "ティラノサウルスは何年くらいでおとなになった?",
    answerItems: ["5年", "10年", "20年", "40年"],
    correct: "20年",
    explanation: "",
  },
  {
    id: 37,
    question: "ティラノサウルスにあったかもしれないものは?",
    answerItems: ["角", "羽毛", "くちばし", "(翼)[つばさ]"],
    correct: "羽毛",
    explanation: "",
  },
  {
    id: 38,
    question: "ティラノサウルスよりも頭骨が長い肉食恐竜は?",
    answerItems: [
      "アロサウルス",
      "ギガノトサウルス",
      "イグアノドン",
      "トリケラトプス",
    ],
    correct: "ギガノトサウルス",
    explanation: "",
  },
  {
    id: 39,
    question: "ティラノサウルスの(祖先)[そせん]はどこから来た?",
    answerItems: ["アジア", "北アメリカ", "ヨーロッパ", "オーストラリア"],
    correct: "アジア",
    explanation: "",
  },
  {
    id: 40,
    question: "オルニトミムスのなかまは何と呼ばれる?",
    answerItems: ["ダチョウ恐竜", "ペリカン恐竜", "アヒル恐竜", "ワシ恐竜"],
    correct: "ダチョウ恐竜",
    explanation: "",
  },
  {
    id: 41,
    question: "デイノケイルスの特ちょうではないのはどれ?",
    answerItems: [
      "長い頭",
      "大きな前あし",
      "するどく長い歯",
      "もり上がった(背中)[せなか]",
    ],
    correct: "するどく長い歯",
    explanation: "",
  },
  {
    id: 42,
    question: "デイノケイルスの名前の意味は?",
    answerItems: [
      "おそろしい手",
      "ニワトリもどき",
      "1本のかぎづめ",
      "大きなどろぼう",
    ],
    correct: "おそろしい手",
    explanation: "",
  },
  {
    id: 43,
    question: "1m近くのつめがある、大きな前あしをもつものは?",
    answerItems: [
      "アラシャサウルス",
      "テリジノサウルス",
      "パタゴニクス",
      "モノニクス",
    ],
    correct: "テリジノサウルス",
    explanation: "",
  },
  {
    id: 44,
    question: "テリジノサウルスの大きな前あしは何に使われた?",
    answerItems: [
      "葉をかき集めて食べた",
      "寝るための穴を(掘)[ほ]った",
      "木によじ登った",
      "木を切り倒した",
    ],
    correct: "葉をかき集めて食べた",
    explanation: "",
  },
  {
    id: 45,
    question: "テリジノサウルスは食べたものをどうやって消化していた?",
    answerItems: [
      "走って消化をうながした",
      "大きな(胃)[い]と(腸)[ちょう]で消化した",
      "口の中でよくかんだ",
      "つめで細かく切ってから食べた",
    ],
    correct: "大きな(胃)[い]と(腸)[ちょう]で消化した",
    explanation: "",
  },
  {
    id: 46,
    question:
      "(卵)[たまご]の化石のそばから化石が発見され「(卵)[たまご]どろぼう」と名づけられたのは?",
    answerItems: [
      "オヴィラプトル",
      "ユタラプトル",
      "ミクロラプトル",
      "ギガントラプトル",
    ],
    correct: "オヴィラプトル",
    explanation: "",
  },
  {
    id: 47,
    question: "オヴィラプトルのなかまで(最大)[さいだい]なのはどれ?",
    answerItems: [
      "コンコラプトル",
      "オヴィラプトル",
      "ギガントラプトル",
      "ヴェロキラプトル",
    ],
    correct: "ギガントラプトル",
    explanation: "",
  },
  {
    id: 48,
    question: "(卵)[たまご]におおいかぶさるような化石が見つかっているのは?",
    answerItems: [
      "ヴェロキラプトル",
      "モノニクス",
      "ユタラプトル",
      "シティパティ",
    ],
    correct: "シティパティ",
    explanation: "",
  },
  {
    id: 49,
    question: "オヴィラプトルのなかまの(卵)[たまご]の(並)[なら]べ方は?",
    answerItems: [
      "(一直線)[いっちょくせん]に(並)[なら]べる",
      "ランダムに(並)[なら]べる",
      "ドーナツのように丸く(並)[なら]べる",
      "四角形に(並)[なら]べる",
    ],
    correct: "ドーナツのように丸く(並)[なら]べる",
    explanation: "",
  },
  {
    id: 50,
    question: "デイノニクスの名前の意味は?",
    answerItems: [
      "(棒)[ぼう]のような尾",
      "するどい(牙)[きば]",
      "おそろしいかぎづめ",
      "美しい(翼)[つばさ]",
    ],
    correct: "おそろしいかぎづめ",
    explanation: "",
  },
  {
    id: 51,
    question: "デイノニクスはかぎづめを何に使っていた?",
    answerItems: [
      "えものを押さえつけていた",
      "敵に見せびらかしていた",
      "走るのに使っていた",
      "木に登るのに使っていた",
    ],
    correct: "えものを押さえつけていた",
    explanation: "",
  },
  {
    id: 52,
    question: "ヴェロキラプトルの有名な化石とは?",
    answerItems: [
      "なかまどうしで喧嘩している化石",
      "(卵)[たまご]を温めている化石",
      "飛んでいる姿勢の化石",
      "ほかの恐竜と戦っている化石",
    ],
    correct: "ほかの恐竜と戦っている化石",
    explanation: "",
  },
  {
    id: 53,
    question: "ドロマエオサウルスのなかまで(最大)[さいだい]のものは?",
    answerItems: [
      "ヴェロキラプトル",
      "ユタラプトル",
      "ミクロラプトル",
      "デイノニクス",
    ],
    correct: "ユタラプトル",
    explanation: "",
  },
  {
    id: 54,
    question: "アーケオプテリクスが発見された国は?",
    answerItems: ["アメリカ", "イギリス", "ドイツ", "イタリア"],
    correct: "ドイツ",
    explanation: "",
  },
  {
    id: 55,
    question: "ブラキオサウルスはどのなかま?",
    answerItems: ["竜脚形類", "長首竜類", "長尾竜類", "巨大竜類"],
    correct: "竜脚形類",
    explanation: "",
  },
  {
    id: 56,
    question: "マメンチサウルスの特ちょうは?",
    answerItems: [
      "細長い頭",
      "するどい歯",
      "(背中)[せなか]の骨の板",
      "非常に長い首",
    ],
    correct: "非常に長い首",
    explanation: "",
  },
  {
    id: 57,
    question: "ディプロドクスの歯の特ちょうは?",
    answerItems: [
      "ナイフのようにとがっていた",
      "口の先だけにあった",
      "平らだった",
      "歯がなかった",
    ],
    correct: "口の先だけにあった",
    explanation: "",
  },
  {
    id: 58,
    question: "スーパーサウルスの正しい姿勢は?",
    answerItems: [
      "首と尾が水平だった",
      "2本のあしで立っていた",
      "尾を引きずっていた",
      "尾を丸めていた",
    ],
    correct: "首と尾が水平だった",
    explanation: "",
  },
  {
    id: 59,
    question: "(竜脚類)[りゅうきゃくるい]が植物の消化のためにしていたことは?",
    answerItems: [
      "首でおなかをさすった",
      "石を飲みこんだ",
      "運動をした",
      "よくかんで食べた",
    ],
    correct: "石を飲みこんだ",
    explanation: "",
  },
  {
    id: 60,
    question: "アマルガサウルスの特ちょうは?",
    answerItems: [
      "首の2枚の(帆)[ほ]",
      "尾のとげ",
      "骨の(鎧)[よろい]",
      "尾の先のハンマー",
    ],
    correct: "首の2枚の(帆)[ほ]",
    explanation: "",
  },
  {
    id: 61,
    question: "ニジェールサウルスの顔の特ちょうは?",
    answerItems: [
      "大きな歯が4本あった",
      "横に一直線の口",
      "大きくとび出た目",
      "長くのびた鼻",
    ],
    correct: "横に一直線の口",
    explanation: "",
  },
  {
    id: 62,
    question: "ブラキオサウルスの名前の意味は?",
    answerItems: ["山トカゲ", "首トカゲ", "岩トカゲ", "うでトカゲ"],
    correct: "うでトカゲ",
    explanation: "",
  },
  {
    id: 63,
    question: "ブラキオサウルスの背の高さは?",
    answerItems: ["約3m", "約7m", "約15m", "約25m"],
    correct: "約15m",
    explanation: "",
  },
  {
    id: 64,
    question: "サルタサウルスの特ちょうは?",
    answerItems: [
      "首に(並)[なら]ぶ長いとげ",
      "肩のとげ",
      "(背中)[せなか]の骨の(鎧)[よろい]",
      "尾のとげ",
    ],
    correct: "(背中)[せなか]の骨の(鎧)[よろい]",
    explanation: "",
  },
  {
    id: 65,
    question: "ステゴサウルスはどのなかま?",
    answerItems: ["(剣竜類)[けんりゅうるい]", "とげ竜類", "針竜類", "牙竜類"],
    correct: "(剣竜類)[けんりゅうるい]",
    explanation: "",
  },
  {
    id: 66,
    question: "ステゴサウルスの(背中)[せなか]の板の(並)[なら]び方は?",
    answerItems: [
      "1列で1直線",
      "2列で横向き",
      "2列でたがいちがい",
      "3列でたがいちがい",
    ],
    correct: "2列でたがいちがい",
    explanation: "",
  },
  {
    id: 67,
    question: "ステゴサウルスの(背中)[せなか]の板の(役割)[やくわり]は?",
    answerItems: [
      "(武器)[ぶき]として使った",
      "(鎧)[よろい]として使った",
      "飛ぶのに使った",
      "体温調節に使った",
    ],
    correct: "体温調節に使った",
    explanation: "",
  },
  {
    id: 68,
    question: "ステゴサウルスが主に食べていたのは?",
    answerItems: [
      "(背)[せ]の低い植物",
      "地面のトカゲ",
      "水中の魚",
      "ほかの恐竜",
    ],
    correct: "(背)[せ]の低い植物",
    explanation: "",
  },
  {
    id: 69,
    question: "ステゴサウルスの身を守る(武器)[ぶき]は?",
    answerItems: [
      "するどいつめ",
      "とがったくちばし",
      "(尾)[お]についたとげ",
      "(背中)[せなか]の板",
    ],
    correct: "(尾)[お]についたとげ",
    explanation: "",
  },
  {
    id: 70,
    question: "ステゴサウルスののどにあったものは?",
    answerItems: [
      "地面を見られる目",
      "骨の(鎧)[よろい]",
      "たくさんのとげ",
      "子どもを入れるふくろ",
    ],
    correct: "骨の(鎧)[よろい]",
    explanation: "",
  },
  {
    id: 71,
    question: "ステゴサウルスの名前の意味は?",
    answerItems: [
      "(柱)[はしら]のトカゲ",
      "(門)[もん]のトカゲ",
      "(窓)[まど]があいたトカゲ",
      "屋根でおおわれたトカゲ",
    ],
    correct: "屋根でおおわれたトカゲ",
    explanation: "",
  },
  {
    id: 72,
    question: " アンキロサウルスはどの仲間?",
    answerItems: [
      "(戦車竜類)[せんしゃりゅうるい]",
      "アルマジロ(竜類)[りゅうり]",
      "(鎧竜類)[よろいりゅうるい]",
      "とげ(竜類)[りゅうるい]",
    ],
    correct: "(鎧竜類)[よろいりゅうるい]",
    explanation: "",
  },
  {
    id: 73,
    question: "(鎧竜類)[よろいりゅうるい]の(鎧)[よろい]は何でできている?",
    answerItems: [
      "皮ふの中にできる骨",
      "固まった毛",
      "巨大化した歯",
      "重なった羽毛",
    ],
    correct: "皮ふの中にできる骨",
    explanation: "",
  },
  {
    id: 74,
    question: "(鎧竜類)[よろいりゅうるい]の(鎧)[よろい]のひみつは?",
    answerItems: [
      "中はスカスカで(軽)[かる]く、動きやすかった",
      "ピンチのときにはぬぎ捨てられた",
      "カメのように中に引っこむことができた",
      "起こると色が変わった",
    ],
    correct: "中はスカスカで(軽)[かる]く、動きやすかった",
    explanation: "",
  },
  {
    id: 75,
    question: "ボレアロペルタの(背中)[せなか]側の皮ふの色は?",
    answerItems: ["青色", "赤茶色", "黄緑色", "白色"],
    correct: "赤茶色",
    explanation: "",
  },
  {
    id: 76,
    question: "アンキロサウルスの戦い方とは?",
    answerItems: [
      "がんじょうなあごでかみつく",
      "尾のハンマーをふりまわす",
      "丸まって体当たりをする",
      "頭から(突進)[とっしん]する",
    ],
    correct: "尾のハンマーをふりまわす",
    explanation: "",
  },
  {
    id: 77,
    question: "ズールの化石はどんな様子でみつかった?",
    answerItems: [
      "子どもの骨が見つかった",
      "戦っている(状態)[じょうたい]で見つかった",
      "皮ふなどが残っていた",
      "ふんが見つかった",
    ],
    correct: "皮ふなどが残っていた",
    explanation: "",
  },
  {
    id: 78,
    question: "イグアノドンの前あしの親指は最初何だと考えられていた?",
    answerItems: [
      "鼻の先の角",
      "(牙)[きば]",
      "後ろのあしのつめ",
      "(尾)[お]の先のとげ",
    ],
    correct: "鼻の先の角",
    explanation: "",
  },
  {
    id: 79,
    question: "イグアノドンの名前の意味は?",
    answerItems: [
      "イグアナの角",
      "イグアナの目",
      "イグアナの歯",
      "イグアナのつめ",
    ],
    correct: "イグアナの歯",
    explanation: "",
  },
  {
    id: 80,
    question: "ハドロサウルスのなかまは、口の形から何と呼ばれる?",
    answerItems: [
      "トリノクチ竜",
      "オオクチバシ竜",
      "カモノハシ竜",
      "ワシノハシ竜",
    ],
    correct: "カモノハシ竜",
    explanation: "",
  },
  {
    id: 81,
    question: "ハドロサウルスのなかまは、植物をどう食べていた?",
    answerItems: [
      "かまずに丸のみしていた",
      "歯ですりつぶしていた",
      "木の実しか食べなかった",
      "つめで細かくしていた",
    ],
    correct: "歯ですりつぶしていた",
    explanation: "",
  },
  {
    id: 82,
    question: "ハドロサウルスのなかまの歯のひみつは?",
    answerItems: [
      "植物を切り取るはさみのようになっていた",
      "すり減ると次の歯が(準備)[じゅんび]されていた",
      "(抜)[ぬ]けることがなかった",
      "1本1本がとがっていた",
    ],
    correct: "すり減ると次の歯が(準備)[じゅんび]されていた",
    explanation: "",
  },
  {
    id: 83,
    question: "エドモントサウルスのめずらしい化石とは?",
    answerItems: [
      "(脳)[のう]の化石",
      "体の色が残った化石",
      "ミイラの化石",
      "羽毛のある化石",
    ],
    correct: "ミイラの化石",
    explanation: "",
  },
  {
    id: 84,
    question: "パラサウロロフスの頭の(突起)[とっき]はどうなっていた?",
    answerItems: [
      "うすくふくらんでいた",
      "骨がつまっていた",
      "中が(空洞)[くうどう]になっていた",
      "のびちぢみした",
    ],
    correct: "中が(空洞)[くうどう]になっていた",
    explanation: "",
  },
  {
    id: 85,
    question: "パキケファロサウルスはどのなかま?",
    answerItems: [
      "(大頭竜類)[だいとうりゅうるい]",
      "(堅頭竜類)[けんとうりゅうるい]",
      "ヘルメット(竜類)[りゅうるい]",
      "(骨頭竜類)[こっとうりゅうるい]",
    ],
    correct: "(堅頭竜類)[けんとうりゅうるい]",
    explanation: "",
  },
  {
    id: 86,
    question: "パキケファロサウルスの頭の骨の(厚)[あつ]さはどれくらい?",
    answerItems: ["約3cm", "約10cm", "約25cm", "約50cm"],
    correct: "約25cm",
    explanation: "",
  },
  {
    id: 87,
    question:
      "現在も(議論)[ぎろん]が続いている、パキケファロサウルスの頭の使い方は?",
    answerItems: [
      "頭で逆立ちした",
      "太陽の光を(反射)[はんしゃ]した",
      "(穴)[あな]を(掘)[ほ]った",
      "頭突きをした",
    ],
    correct: "頭突きをした",
    explanation: "",
  },
  {
    id: 88,
    question: "トリケラトプスはどのなかま?",
    answerItems: [
      "(剣竜類)[けんりゅうるい]",
      "(盾竜類)[じゅんりゅうるい]",
      "(角竜類)[つのりゅうるい]",
      "(牙竜類)[きばりゅうるい]",
    ],
    correct: "(角竜類)[つのりゅうるい]",
    explanation: "",
  },
  {
    id: 89,
    question: "(角竜類)[つのりゅうるい]のフリルは何でできている?",
    answerItems: ["頭の骨", "肩の骨", "首の骨", "羽毛"],
    correct: "頭の骨",
    explanation: "",
  },
  {
    id: 90,
    question: "次のうち(角竜類)[つのりゅうるい]はどれ?",
    answerItems: [
      "プシッタコサウルス",
      "ステゴサウルス",
      "ケラトサウルス",
      "イグアノドン",
    ],
    correct: "プシッタコサウルス",
    explanation: "",
  },
  {
    id: 91,
    question: "プシッタコサウルスの体の色は何?",
    answerItems: ["青色", "黄色", "赤茶色", "黒色"],
    correct: "赤茶色",
    explanation: "",
  },
  {
    id: 92,
    question: "プロトケラトプスは成長するとどうなった?",
    answerItems: [
      "目の上に角が生えた",
      "頭のフリルが大きくなった",
      "羽毛が生えた",
      "あしが細長くなった",
    ],
    correct: "頭のフリルが大きくなった",
    explanation: "",
  },
  {
    id: 93,
    question: "セントロサウルスの頭のフリルのとげの様子はどれ?",
    answerItems: [
      "6本の長いとげがのびる",
      "バナナのような形で前にのびる",
      "真横に2本のびる",
      "とげはない",
    ],
    correct: "バナナのような形で前にのびる",
    explanation: "",
  },
  {
    id: 94,
    question: "セントロサウルスの化石の見つかった様子はどれ?",
    answerItems: [
      "(敵)[てき]と戦ったままで見つかった",
      "ミイラの(状態)[じょゆたい]で見つかった",
      "(共食)[ともぐ]いをしていた",
      "数百〜数千頭の(群)[む]れで見つかった",
    ],
    correct: "数百〜数千頭の(群)[む]れで見つかった",
    explanation: "",
  },
  {
    id: 95,
    question: "トリケラトプスのおとなの(最大)[さいだい]の全長はどれ?",
    answerItems: ["2m", "4m", "8m", "12m"],
    correct: "8m",
    explanation: "",
  },
  {
    id: 96,
    question: "トリケラトプスの名前の意味は?",
    answerItems: [
      "3本の角のある顔",
      "3か所で見つかった顔",
      "3人が発見した顔",
      "3回見つかった顔",
    ],
    correct: "3本の角のある顔",
    explanation: "",
  },
  {
    id: 97,
    question: "トリケラトプスの子どもの頭はどんな様子だった?",
    answerItems: [
      "フリルが長かった",
      "顔が長かった",
      "角が短かった",
      "下あごが出ていた",
    ],
    correct: "角が短かった",
    explanation: "",
  },
  {
    id: 98,
    question: "トリケラトプスと同じ時代・(地域)[ちいき]にくらしていたのは?",
    answerItems: [
      "ティラノサウルス",
      "アロサウルス",
      "ステゴサウルス",
      "ギガノトサウルス",
    ],
    correct: "ティラノサウルス",
    explanation: "",
  },
  {
    id: 99,
    question: "カスモサウルスの名前の意味は?",
    answerItems: [
      "(盾)[たて]のトカゲ",
      "大きな頭のトカゲ",
      "赤いトカゲ",
      "穴のあいたトカゲ",
    ],
    correct: "穴のあいたトカゲ",
    explanation: "",
  },
  {
    id: 100,
    question: "この中で植物食の恐竜はどれ?",
    answerItems: [
      "アルバートサウルス",
      "パキケファロサウルス",
      "バリオニクス",
      "コエロフィシス",
    ],
    correct: "パキケファロサウルス",
    explanation: "",
  },
  {
    id: 101,
    question: "この中で、いちばん大きい恐竜は?",
    answerItems: [
      "アマルガサウルス",
      "ブラキオサウルス",
      "スーパーサウルス",
      "ニジェールサウルス",
    ],
    correct: "スーパーサウルス",
    explanation: "",
  },
  {
    id: 102,
    question: "この中で、いちばん体の長い肉食恐竜は?",
    answerItems: [
      "ティラノサウルス",
      "スピノサウルス",
      "アロサウルス",
      "ケラトサウルス",
    ],
    correct: "スピノサウルス",
    explanation: "",
  },
  {
    id: 103,
    question: "この中で、いちばん小さい恐竜は?",
    answerItems: [
      "エピデキシプテリクス",
      "コエロフィシス",
      "アンキオルニス",
      "オヴィラプトル",
    ],
    correct: "エピデキシプテリクス",
    explanation: "",
  },
  {
    id: 104,
    question: "この中で、いちばん走るのが(得意)[とくい]だったのは?",
    answerItems: [
      "トリケラトプス",
      "ティラノサウルス",
      "オルニトミムス",
      "ディプロドクス",
    ],
    correct: "オルニトミムス",
    explanation: "",
  },
  {
    id: 105,
    question: "この中で、いちばん体重が重いと考えられているのは?",
    answerItems: [
      "アルゼンチノサウルス",
      "エオラプトル",
      "スピノサウルス",
      "トリケラトプス",
    ],
    correct: "アルゼンチノサウルス",
    explanation: "",
  },
  {
    id: 106,
    question:
      "鳥(以外)[いがい]の恐竜が生んだ(卵)[たまご]の化石の中で、いちばん小さなものの大きさは?",
    answerItems: ["約4.5cm", "約10cm", "約20cm", "約50cm"],
    correct: "約4.5cm",
    explanation: "",
  },
  {
    id: 107,
    question: "次のうち、恐竜はどれ?",
    answerItems: [
      "プテラノドン",
      "ズール",
      "フタバサウルス",
      "イクチオサウルス",
    ],
    correct: "ズール",
    explanation: "",
  },
  {
    id: 108,
    question: "翼竜の(翼)[つばさ]は何でできていた?",
    answerItems: ["羽毛", "皮ふ", "(肋骨)[ろっこつ]", "うろこ"],
    correct: "皮ふ",
    explanation: "",
  },
  {
    id: 109,
    question: "プテラノドンの翼を支えている指はどれ?",
    answerItems: ["親指", "人差し指", "中指", "薬指"],
    correct: "薬指",
    explanation: "",
  },
  {
    id: 110,
    question: "ケツァルコアトルスの翼を広げた大きさは?",
    answerItems: ["6m", "12m", "24m", "48m"],
    correct: "12m",
    explanation: "",
  },
  {
    id: 111,
    question: "(魚竜)[ぎょるい]が誕生した時期はいつ?",
    answerItems: [
      "(三畳紀前期)[さんじょうきぜんき]",
      "ジュラ(紀後期)[きこうき]",
      "(白亜紀前期)[はくあきぜんき]",
      "(白亜紀後期)｢はくあきこうき｣",
    ],
    correct: "(三畳紀前期)[さんじょうきぜんき]",
    explanation: "",
  },
  {
    id: 112,
    question: "イクチオサウルスについて正しいのは?",
    answerItems: [
      "イルカの祖先",
      "恐竜が(絶滅)[ぜつめつ]した後も生きていた",
      "(卵)[たまご]ではなく子どもを(産)[う]んだ",
      "陸に上がって(卵)[たまご]を(産)[う]んだ",
    ],
    correct: "(卵)[たまご]ではなく子どもを(産)[う]んだ",
    explanation: "",
  },
  {
    id: 113,
    question: "この中で(最)[もっと]も大きな(魚竜)[ぎょるい]はどれ?",
    answerItems: [
      "ウタツサウルス",
      "ショニサウルス",
      "タラットアルコン",
      "イクチオサウルス",
    ],
    correct: "ショニサウルス",
    explanation: "",
  },
  {
    id: 114,
    question: "(首長竜)[くびながりゅう]について正しいのは?",
    answerItems: [
      "首が短いものもいた",
      "夜は陸で眠った",
      "恐竜から進化した",
      "植物を食べた",
    ],
    correct: "首が短いものもいた",
    explanation: "",
  },
  {
    id: 115,
    question: "日本の(首長竜)[くびながりゅう]、フタバサウルスを発見したのは?",
    answerItems: ["教授", "高校生", "小説家", "警察官"],
    correct: "高校生",
    explanation: "",
  },
  {
    id: 116,
    question: "モササウルスのなかまは何に近い生き物?",
    answerItems: ["トカゲ", "ワニ", "魚", "鳥"],
    correct: "トカゲ",
    explanation: "",
  },
  {
    id: 117,
    question: "モササウルスのあごのひみつは何?",
    answerItems: [
      "歯がなかった",
      "歯を折りたためた",
      "上あごだけ長かった",
      "下あごの骨が曲がった",
    ],
    correct: "下あごの骨が曲がった",
    explanation: "",
  },
  {
    id: 118,
    question:
      "(白亜紀後期)[はくあきこうき]にいたワニのなかま、ディノスクスの全長は?",
    answerItems: ["3m", "7m", "9m", "12m"],
    correct: "12m",
    explanation: "",
  },
  {
    id: 119,
    question: "古代のカメ、アルケロンの全長は?",
    answerItems: ["50cm", "1.4m", "4.6m", "8m"],
    correct: "4.6m",
    explanation: "",
  },
  {
    id: 120,
    question:
      "日本でいちばん多く学名(世界共通の生物学的な名前)のついた恐竜がみつかっている都道府県は?",
    answerItems: ["北海道", "福井県", "石川県", "兵庫県"],
    correct: "福井県",
    explanation: "",
  },
  {
    id: 121,
    question: "福井県でみつかっている肉食恐竜は?",
    answerItems: [
      "フクイサウルス",
      "フクイティタン",
      "フクイラプトル",
      "コシサウルス",
    ],
    correct: "フクイラプトル",
    explanation: "",
  },
  {
    id: 122,
    question: "初めて日本人の名づけた恐竜はどれ",
    answerItems: [
      "ニッポノサウルス",
      "アルバロフォサウルス",
      "タンバティタニス",
      "カムイサウルス",
    ],
    correct: "ニッポノサウルス",
    explanation: "",
  },
  {
    id: 123,
    question:
      "北海道で(全身骨格)[ぜんしんこっかく]が発見され、2019年に(新種)[しんしゅ]として発表された恐竜は?",
    answerItems: [
      "ホッカイサウルス",
      "サムイサウルス",
      "カムイサウルス",
      "キタノサウルス",
    ],
    correct: "カムイサウルス",
    explanation: "",
  },
  {
    id: 124,
    question:
      "(白亜紀末)[はくあきまつ]に、たくさんの恐竜が(絶滅)[ぜつめつ]した主な(原因)[げんいん]として有力な説は?",
    answerItems: [
      "(隕石)[いんせき]が地球に(衝突)[しょうとつ]した",
      "(哺乳類)[ほにゅうるい]に(卵)[たまご]を食べつくされた",
      "(陸地)[りくち]がなくなった",
      "植物の(毒)[どく]で(中毒)[ちゅうどく]になった",
    ],
    correct: "(隕石)[いんせき]が地球に(衝突)[しょうとつ]した",
    explanation: "",
  },
  {
    id: 125,
    question:
      "(白亜期末)[はくあきまつ]に(隕石)[いんせき]が(衝突)[しょうとつ]したとき、おこらなかったことはどれ?",
    answerItems: [
      "(津波)[つなみ]が発生した",
      "全ての海水が(蒸発)[じょうはつ]した",
      "山火事が発生した",
      "地球全体が寒くなった",
    ],
    correct: "全ての海水が(蒸発)[じょうはつ]した",
    explanation: "",
  },
  {
    id: 126,
    question:
      "(白亜期末)[はくあきまつ]に(衝突)[しょうとつ]した(隕石)[いんせき]のクレーターはどこにある?",
    answerItems: ["アメリカ", "メキシコ", "オーストラリア", "インド"],
    correct: "メキシコ",
    explanation: "",
  },
  {
    id: 127,
    question: "恐竜から進化したと考えられている生き物は?",
    answerItems: ["ワニ", "ライオン", "鳥", "ヘビ"],
    correct: "鳥",
    explanation: "",
  },
  {
    id: 128,
    question:
      "恐竜の子育ての(研究)[けんきゅう]がもり上がるきっかけになったのは?",
    answerItems: [
      "ティラノサウルス",
      "トリケラトプス",
      "マイアサウラ",
      "ディプロドクス",
    ],
    correct: "マイアサウラ",
    explanation: "",
  },
  {
    id: 129,
    question:
      "いちばん(最初)[さいしょ]に学名(世界共通の生物学的な名前)がつけられた恐竜は?",
    answerItems: [
      "メガロサウルス",
      "イグアノドン",
      "アロサウルス",
      "ティラノサウルス",
    ],
    correct: "メガロサウルス",
    explanation: "",
  },
  {
    id: 130,
    question: "恐竜が(誕生)[誕生]したころにまだいなかったのは?",
    answerItems: [
      "(魚類)[ぎょるい]",
      "(両生類)[りょうせいるい]",
      "(鳥類)[ちょうるい]",
      "(昆虫類)[こんちゅうるい]",
    ],
    correct: "(鳥類)[ちょうるい]",
    explanation: "",
  },
  {
    id: 131,
    question:
      "(南半球)[みなみはんきゅう]にあり、たくさんの恐竜の化石が発見されている国は?",
    answerItems: ["モンゴル", "アルゼンチン", "アメリカ", "カナダ"],
    correct: "アルゼンチン",
    explanation: "",
  },
];

export default quizItems;
