import Quiz from "./Quiz";
import quizItems from "./QuizItems";
import { useEffect, useState } from "react";
import { useAtom } from "jotai"; // Jotaiのインポート
import { useNavigate, useLocation } from "react-router-dom";
import { quizItemIdListAtom, quizIndexAtom, isAnswerAtom } from "./State";

function QuizContainer() {
  const [quizItemIdList, setQuizItemIdList] = useAtom(quizItemIdListAtom);
  const [quizIndex, setQuizIndex] = useAtom(quizIndexAtom);
  const [isAnswered, setIsAnswered] = useAtom(isAnswerAtom);
  const [currentId, setCurrentId] = useState(0);

  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const size =
    query.get("size") === null ? quizItems.length : query.get("size");

  const range = (n) => [...Array(n).keys()];
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // ランダムなインデックスを取得
      [array[i], array[j]] = [array[j], array[i]]; // 要素を入れ替える
    }
    return array;
  };

  useEffect(() => {
    const seeds = range(quizItems.length);
    const shuffledArray = shuffleArray(seeds);
    const ql = shuffledArray.slice(0, size);
    setQuizItemIdList(ql);
    setQuizIndex(ql[0]);
  }, []);

  const handleClick = () => {
    const nextId = currentId + 1;
    setQuizIndex(quizItemIdList[nextId]);
    setCurrentId(nextId);
    setIsAnswered(false);
  };

  const handleResult = () => {
    setQuizIndex(0);
    setIsAnswered(false);
    navigate("/result");
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Quiz
        quizItem={quizItems[quizIndex]}
        id={quizItemIdList[quizIndex]}
        key={quizIndex}
      />
      <div className="my-8 p-4">
        {isAnswered &&
          (quizItemIdList.length - 1 === currentId ? (
            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-4 px-12 rounded text-4xl"
              onClick={() => handleResult()}
            >
              結果をみる
            </button>
          ) : (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-12 rounded text-4xl"
              onClick={() => handleClick()}
            >
              次へ
            </button>
          ))}
      </div>
    </div>
  );
}

export default QuizContainer;
