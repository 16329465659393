import React from "react";
import "./CRuby.css";

export const CRuby = ({ text }) => {
  const parseRuby = (text) => {
    const regex = /\(([^\)]+)\)\[([^\]]+)\]|([^\(\[]+)/g;
    let result = "";
    let match;

    while ((match = regex.exec(text)) !== null) {
      if (match[3]) {
        result += match[3];
      } else {
        result += `<ruby><rb>${match[1]}</rb><rt>${match[2]}</rt></ruby>`;
      }
    }
    return result;
  };

  const rubyContent = parseRuby(text);

  return <p dangerouslySetInnerHTML={{ __html: rubyContent }} />;
};
