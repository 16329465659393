import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { quizResultAtom, quizIndexAtom, isAnswerAtom } from "./State";
import { CRuby } from "./CRuby";

function Quiz({ quizItem, id }) {
  const [answer, setAnswer] = useState(null);
  const [active, setActive] = useState(null);
  const [message, setMessage] = useState(null);

  const [quizResults, setQuizResults] = useAtom(quizResultAtom); // 間違ったクイズのリスト(QuizId)
  const [quizIndex, setQuizIndex] = useAtom(quizIndexAtom); // 対象クイズ一覧のAtom
  const [isAnswered, setIsAnswered] = useAtom(isAnswerAtom);

  useEffect(() => {}, []);

  // 答えに応じてメッセージを設定
  useEffect(() => {
    if (answer != null) {
      const isCorrect = answer === quizItem.correct;
      setMessage(isCorrect ? "正解!" : "ざんねん");
      if (!isCorrect) {
        setQuizResults([...quizResults, quizItem.id]);
      }
    }
  }, [answer]);

  const handleClick = (index, element) => {
    if (isAnswered) return; // 一度答えを選んだら何もしない
    setActive(index);
    setAnswer(element);
    setIsAnswered(true); // クリック後、選択不可にする
  };

  const qIndex = (idx) => {
    const index = idx + 1;
    let number = "①";
    switch (index) {
      case 1:
        number = "①";
        break;
      case 2:
        number = "②";
        break;
      case 3:
        number = "③";
        break;
      case 4:
        number = "④";
        break;
      default:
        break;
    }
    return number;
  };

  return (
    <div className="App">
      <div className="my-16">
        <h1 className="text-2xl font-bold">問題 {quizIndex + 1}</h1>
        <p className="text-4xl my-4">
          <CRuby text={quizItem.question} />
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {quizItem.answerItems.map((answerItem, index) => (
          <div
            key={index}
            className={`cursor-pointer p-4 text-left text-3xl hover:bg-lime-200 ${
              active === index ? "text-red-500" : "text-black"
            } ${isAnswered ? "cursor-not-allowed opacity-50" : ""}`}
            onClick={() => handleClick(index, answerItem)}
          >
            <p className="flex flex-row items-end">
              <div>{qIndex(index)}</div>
              <CRuby text={answerItem} />
            </p>
          </div>
        ))}
      </div>

      <div className="mt-12 text-xl">
        {answer && <div className="text-4xl">{message}</div>}
        {message === "ざんねん" && (
          <div className="text-2xl my-8">{quizItem.explanation}</div>
        )}
      </div>
    </div>
  );
}

export default Quiz;
